import React, { useState, useEffect, useRef } from "react"
import {graphql, Link} from "gatsby";
import Marquee from "react-fast-marquee";
import Layout from "../components/Layout";
import BlogEntry from "../components/BlogEntry";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Head from "../components/Head";


export const query = graphql`
    {
    allSanityBlog{
    edges
    {
    node{
      title
      slug{
        current
      }
      introtext
      blogtag
      image{
      asset{
      gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
      )
      }
      }
      imageoverview{
      asset{
      gatsbyImageData(
          formats: [AUTO, WEBP, AVIF])
      }
      }
      status
      featured
      datum
    }
  }
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }
}
`

const Blog = ({data}) => {

    var colors = [
        'f2c1b1',
        'b7b783',
        'ffffff',
    ];

    const imageFeature = getImage(data.allSanityBlog.edges[0].node.image.asset.gatsbyImageData);

    const allArticles = data.allSanityBlog.edges;

    const allArticlesSorted = allArticles.sort(function(a,b){return new Date(b.node.datum) - new Date(a.node.datum)});

    return (
        <Layout settings={data.allSanitySettings.edges[0].node}>
            <Head title="Blog"/>
            <Marquee gradient={false} speed={40} className={'mobilemargin'}>
                <div className="marqueeText color-orange">
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                </div>
            </Marquee>
            <div className="blogpage">

                    {allArticles.map(({node: blog}, index) => {
                            if(blog.featured){
                                return (
                                <div className="blogfeature__container">
                                    {blog.image.asset ? <GatsbyImage image={blog.image.asset.gatsbyImageData} alt={blog.title}></GatsbyImage> : <></>}
                                    <div className="blogfeature__textwrapper">
                                        <h2>{blog.title}</h2>
                                        <p className="small">{blog.introtext}</p>
                                        <Link to={blog.slug.current} className={"btn filled small smalltext bold blogbtn white hover-moveup"}>JETZT LESEN</Link>
                                    </div>
                                </div>)
                                    }
                        }
                    )}
                <div className="blogList">
                    {allArticlesSorted.map(({node: blog}, index) => {
                        if(blog.status && !blog.featured){
                            console.log(blog.title + "  -  " + blog.datum);
                            return <BlogEntry title={blog.title} text={blog.introtext} slug={blog.slug.current} tags={blog.blogtag} color={colors[(index % 3)]} image={blog.imageoverview.asset.gatsbyImageData}></BlogEntry>
                        }
                    }
                    )}
                </div>
            </div>
        </Layout>
    )
}

//                <BlogFeature image={blog.image} headline={blog.headline} text={blog.text} slug={job.slug.current}></BlogFeature>
//image={blog.image} headline={blog.headline} text={blog.text} slug={job.slug.current} tags={job.tags}
export default Blog;


/*
                                    {blog.image ? <GatsbyImage image={blog.image.asset.gatsbyImageData} alt={blog.title}></GatsbyImage> : <></>}

 */
